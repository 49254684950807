@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;  

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.homePageSectionContainer {
    text-align: center;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* -------   Scroll snap causes issues in React Transition Group CSS Transitions    ------- */
    /* scroll-snap-align: start;
    scroll-snap-stop: normal; */
}

.homePageSectionContainer * {
    color: white;
    margin: auto;
    padding: 0;
}

.sectionContent:not(#introSection) {
    padding-left: 10vw;
    width: 60%;
    min-width: 850px;
}

.navItem>a {
    cursor: pointer;
}

h2 {
    top: 0;
    font-size: 40px;
    text-transform: uppercase;
}

.sectionContent {
    position: relative;
}

.sectionContent>div {
    display: flex;
    flex-direction: column;
    height: 70vh;
}

.navigationArrowContainer.up {
    top: 3vh;
}

.navigationArrowContainer.down {
    bottom: 3vh;
}

.homeNavigationArrowContainer.down {
    -webkit-animation: arrowHomeIntroTransition 1s forwards;
            animation: arrowHomeIntroTransition 1s forwards;
}

.homeNavigationArrowContainer.up {
    -webkit-animation: arrowDefaultPositionTransition 1s forwards;
            animation: arrowDefaultPositionTransition 1s forwards;
}

.homeNavigationArrowContainer.down:not(.homeIntroPosition) {
    -webkit-animation: arrowDefaultPositionTransition 1s forwards;
            animation: arrowDefaultPositionTransition 1s forwards;
}

#upArrow {
    border-width: 0 4px 4px 0;
}

#downArrow {
    border-width: 4px 0 0 4px;
}

@-webkit-keyframes arrowDefaultPositionTransition {
    0% {
        margin-left: 0vw;
    }
    100% {
        margin-left: 5vw;
    }
}

@keyframes arrowDefaultPositionTransition {
    0% {
        margin-left: 0vw;
    }
    100% {
        margin-left: 5vw;
    }
}

@-webkit-keyframes arrowHomeIntroTransition {
    0% {
        margin-left: 5vw;
    }
    100% {
        margin-left: 0;
    }
}

@keyframes arrowHomeIntroTransition {
    0% {
        margin-left: 5vw;
    }
    100% {
        margin-left: 0;
    }
}

@media screen and (max-width: 1300px) {
    .sectionContent {
        min-width: unset !important;
        width: unset !important;
        padding-left: unset !important;
    }
    .sectionContent>div {
        height: unset;
    }
    .homeNavigationArrowContainer {
        display: none;
    }
    .homePageSectionContainer {
        text-align: center;
        height: unset;
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .homePageSectionContainer h2 {
        margin-top: 50px;
        font-size: 35px;
    }
}
#introSection {
    /* width: 100%; */
    top: 10vh;
    padding-right: 40%;
}

#descriptionContainer {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
}

#description {
    font-size: 1.25vw;
    width: 60%;
    text-shadow: 0px 0px 5px black, 5px 5px 5px black;
}

#name {
    font-size: 5vw;
    /* font-family: casanova; */
    /* margin-top: 20vh; */
    margin-bottom: 2vh;
    font-weight: 400;
    text-transform: uppercase;
}

#title {
    font-size: 3vw;
    margin-bottom: 3vh;
    font-weight: 650;
    /* font-family: casanova; */
}

#homeResourcesContainer {
    -webkit-animation: none 0s ease 0s 1 normal none running;
            animation: none 0s ease 0s 1 normal none running;
    -webkit-backface-visibility: visible;
            backface-visibility: visible;
    background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
    border: medium none currentColor;
    border-collapse: separate;
    border-image: none;
    border-radius: 0;
    border-spacing: 0;
    bottom: auto;
    box-shadow: none;
    box-sizing: content-box;
    caption-side: top;
    clear: none;
    clip: auto;
    color: #000;
    -webkit-columns: auto;
            columns: auto;
    -webkit-column-count: auto;
            column-count: auto;
    -webkit-column-fill: balance;
            column-fill: balance;
    grid-column-gap: normal;
    -webkit-column-gap: normal;
            column-gap: normal;
    -webkit-column-rule: medium none currentColor;
            column-rule: medium none currentColor;
    -webkit-column-span: 1;
            column-span: 1;
    -webkit-column-width: auto;
            column-width: auto;
    content: normal;
    counter-increment: none;
    counter-reset: none;
    cursor: auto;
    direction: ltr;
    display: inline;
    empty-cells: show;
    float: none;
    font-family: serif;
    font-size: medium;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    height: auto;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    left: auto;
    letter-spacing: normal;
    list-style: disc outside none;
    margin: 0;
    max-height: none;
    max-width: none;
    min-height: 0;
    min-width: 0;
    opacity: 1;
    orphans: 2;
    outline: medium none invert;
    overflow: visible;
    overflow-x: visible;
    overflow-y: visible;
    padding: 0;
    page-break-after: auto;
    page-break-before: auto;
    page-break-inside: auto;
    perspective: none;
    perspective-origin: 50% 50%;
    position: static;
    right: auto;
    -moz-tab-size: 8;
         tab-size: 8;
    table-layout: auto;
    text-align: left;
    text-align-last: auto;
    text-decoration: none;
    text-indent: 0;
    text-shadow: none;
    text-transform: none;
    top: auto;
    transform: none;
    transform-origin: 50% 50% 0;
    transform-style: flat;
    transition: none 0s ease 0s;
    unicode-bidi: normal;
    vertical-align: baseline;
    visibility: visible;
    white-space: normal;
    widows: 2;
    width: auto;
    word-spacing: normal;
    z-index: auto;
    all: initial;
    margin: auto;
    margin-top: 50px;
    display: flex;
    width: 60%;
    justify-content: space-between;
    flex-direction: row;
}

.homeResource {
    padding: 1%;
    width: 20%;
    display: flex;
    text-decoration: none;
    -webkit-animation: removeBackgroundAnimation .5s forwards;
            animation: removeBackgroundAnimation .5s forwards;
    -webkit-filter: drop-shadow(5px 5px 4px black) drop-shadow(1px 1px 0 black);
            filter: drop-shadow(5px 5px 4px black) drop-shadow(1px 1px 0 black)
}

.homeResource+.homeResource {
    margin-left: 5%;
}

.homeResource>p {
    font-size: 25px;
}

.homeResource:hover {
    -webkit-animation: addBackgroundAnimation .5s forwards;
            animation: addBackgroundAnimation .5s forwards;
}

.homeResource>p {
    margin-right: auto;
}

.homeResource>svg {
    margin-left: auto;
}

.homeResource>p, .homeResource>svg {
    -webkit-animation: revertResourceAnimation .5s forwards;
            animation: revertResourceAnimation .5s forwards;
    /* animation-fill-mode: ; */
}

.homeResource:hover>p, .homeResource:hover>svg {
    -webkit-animation: invertResourceAnimation .5s both;
            animation: invertResourceAnimation .5s both;
    /* filter: invert(100%); */
}

@-webkit-keyframes pulse {
    0% {
        transform: scale(1) rotate(45deg);
    }
    50% {
        transform: scale(1.3) rotate(45deg);
    }
    100% {
        transform: scale(1) rotate(45deg);
        /* transition */
    }
}

@keyframes pulse {
    0% {
        transform: scale(1) rotate(45deg);
    }
    50% {
        transform: scale(1.3) rotate(45deg);
    }
    100% {
        transform: scale(1) rotate(45deg);
        /* transition */
    }
}

@-webkit-keyframes invertResourceAnimation {
    0% {
        -webkit-filter: invert(0%);
                filter: invert(0%);
        /* filter: drop-shadow(10px 10px 5px black) */
    }
    100% {
        -webkit-filter: invert(100%);
                filter: invert(100%);
        /* filter: drop-shadow(10px 10px 5px white) */
    }
}

@keyframes invertResourceAnimation {
    0% {
        -webkit-filter: invert(0%);
                filter: invert(0%);
        /* filter: drop-shadow(10px 10px 5px black) */
    }
    100% {
        -webkit-filter: invert(100%);
                filter: invert(100%);
        /* filter: drop-shadow(10px 10px 5px white) */
    }
}

@-webkit-keyframes revertResourceAnimation {
    0% {
        -webkit-filter: invert(100%);
                filter: invert(100%);
        /* filter: drop-shadow(10px 10px 5px white) */
    }
    100% {
        -webkit-filter: invert(0%);
                filter: invert(0%);
        /* filter: drop-shadow(10px 10px 5px black) */
    }
}

@keyframes revertResourceAnimation {
    0% {
        -webkit-filter: invert(100%);
                filter: invert(100%);
        /* filter: drop-shadow(10px 10px 5px white) */
    }
    100% {
        -webkit-filter: invert(0%);
                filter: invert(0%);
        /* filter: drop-shadow(10px 10px 5px black) */
    }
}

@-webkit-keyframes addBackgroundAnimation {
    0% {
        /* background-color: transparent; */
    }
    100% {
        background-color: white;
    }
}

@keyframes addBackgroundAnimation {
    0% {
        /* background-color: transparent; */
    }
    100% {
        background-color: white;
    }
}

@-webkit-keyframes removeBackgroundAnimation {
    0% {
        background-color: white;
    }
    100% {
        /* background-color: transparent; */
    }
}

@keyframes removeBackgroundAnimation {
    0% {
        background-color: white;
    }
    100% {
        /* background-color: transparent; */
    }
}

@media screen and (max-width: 1300px) {
    #introSection {
        padding-right: unset;
        top: 20%;
    }
    #description {
        font-size: 20px;
        width: 100%;
    }
    #name {
        font-size: 50px;
        margin-bottom: 10px;
        font-weight: 400;
        text-transform: uppercase;
    }
    #title {
        font-size: 30px !important;
        margin-bottom: 15px;
        font-weight: 650;
    }
    #homeResourcesContainer {
        margin-top: 50px;
        width: 100%;
        flex-direction: column;
    }
    .homeResource {
        margin-top: 10px;
        width: 40%;
    }
    .homeResource+.homeResource {
        margin-left: auto;
    }
}
.educationCardWrapper {
    width: 100%;
    height: 50%;
}

.educationCardContainer {
    width: 100%;
    height: 90%;
}

.schoolHeader {
    display: flex;
    flex-direction: row;
}

.schoolName {
    display: inline-block;
    font-size: 2vw;
    /* text-decoration: underline; */
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
    color: black;
    text-transform: uppercase;
}

.educationContent {
    padding: 1%;
}

.educationDescription {
    color: black;
    margin-left: 0;
}

.enrolledDates {
    margin-right: 0;
    margin-left: auto;
    color: black;
}

.schoolLocation {
    margin-right: 0;
    margin-left: auto;
    text-decoration: none;
    font-size: 1.75vw;
    font-weight: bold;
    color: black;
}

.schoolHeaderSubText {
    font-size: 1.5vw;
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 1300px) {
    .schoolName {
        font-size: 20px;
    }
    .schoolLocation {
        font-size: 15px
    }
    .schoolHeaderSubText {
        font-size: 12px;
        display: flex;
        flex-direction: row;
    }
}
.courseCard {
    width: 100%;
    height: 100%;
    background-color: #67973f;
    /* margin:  */
}

.courseCardWrapper {
    width: 24%;
    height: 12vh;
    margin: .5%;
}

.courseCard>.front {
    font-size: 1vw;
}

.courseCard>.back {
    /* font-size: .8vw;  */
    font-size: 1.5vh;
    background-color: #89ac60;
}

.courseName {
    font-size: 20px;
}

@media screen and (max-width: 1300px) {
    .courseCardWrapper {
        height: 50px;
        width: 30%;
    }
    .courseName {
        font-size: 12px;
    }
}
.relevantCoursesContainer {
    /* margin-top: 5vh; */
}

.relevantCoursesCardsContainer {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.relevantCoursesHeading {
    color: black;
    font-size: x-large;
    text-decoration: underline;
}

.educationItemWrapper {
    margin: 0;
    background-color: rgb(255, 255, 255, .8);
    width: 100%;
}

@media screen and (max-width: 1300px) {}
.certificationCard {
    width: 100%;
    height: 100%;
    /* margin:  */
}

.certificationCardWrapper {
    width: 24%;
    height: 12vh;
    margin: .5%;
}

.certificationCard>.front {
    flex-direction: column;
    background-color: #67973f;
}

.certificationCard>.back {
    background-color: #89ac60;
}

.certificationCard .certification {
    font-size: 20px;
    font-weight: lighter;
    font-style: italic;
    margin-top: 0;
}

.certificationCard .certificationPlatform {
    font-size: 22px;
    margin-bottom: 0;
    /* font-weight: bold; */
}

@media screen and (max-width: 1300px) {
    .certificationCardWrapper {
        height: 75px;
        width: 30%;
    }
    .certificationCard .certificationPlatform {
        font-size: 12px;
    }
    .certificationCard .certification {
        font-size: 12px;
        font-weight: lighter;
        font-style: italic;
        margin: 0;
    }
    .certificationStatus {
        margin-top: 0;
        font-size: 10px;
    }
}
.relevantCertificationsCardsContainer {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.relevantCertificationsHeading {
    color: black;
    font-size: x-large;
    text-decoration: underline;
}

.certificationItemWrapper {
    background-color: rgb(255, 255, 255, .8);
    width: 100%;
    margin-top: 20px;
}
#educationContainer {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content
}

@media screen and (max-width: 1300px) {
    #educationContainer {
        width: 100vw;
    }
}
.experienceContainer {
    border: 1px solid #29b0ac;
    padding: 10px;
}

.experienceContent {
    background-color: rgb(255, 255, 255, .3);
    position: relative;
    padding: 1%;
}

.experienceSkillsTitle {
    text-decoration: underline;
    font-weight: bold;
}

.connectingLine {
    width: 11%;
    border-top: 2px dashed white;
    position: absolute;
    left: calc(-11% - 20px);
    top: 50%;
}

.experienceContent * {
    text-align: justify;
    padding: 2px 1px 2px 1px;
}

@media screen and (max-width: 1300px) {
    .connectingLine {
        display: none;
    }
    .experienceContainer {
        margin: 5px;
        /* border: unset; */
        /* padding: unset; */
    }
    .experienceContent * {
        padding: unset;
    }
    .experienceContent>h3 {
        font-size: 18px;
        height: 50px;
    }
    .atSymbol {
        display: none;
    }
    .companyName {
        display: block;
    }
}
#verticalBar {
    height: 100%;
    border: 2px solid #29b0ac;
    position: absolute;
    left: 0;
}

#experiencesContainer {
    position: relative;
    text-align: left;
    padding-left: 12%;
    margin-top: 0;
}

#allExperiencesLink {
    font-size: x-large;
    /* margin-bottom: 10vh; */
}

#allExperiencesLinkContainer {
    /* position: absolute; */
    border: 1px solid white;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 10px;
    display: inline;
    -webkit-animation: revertAllExperiencesLinkAnimation .5s forwards;
            animation: revertAllExperiencesLinkAnimation .5s forwards;
}

#allExperiencesLinkContainer:hover {
    -webkit-animation: invertAllExperiencesLinkAnimation .5s forwards;
            animation: invertAllExperiencesLinkAnimation .5s forwards;
}

@-webkit-keyframes invertAllExperiencesLinkAnimation {
    from {
        -webkit-filter: invert(0%);
                filter: invert(0%);
        background-color: transparent;
    }
    to {
        -webkit-filter: invert(100%);
                filter: invert(100%);
        background-color: black;
    }
}

@keyframes invertAllExperiencesLinkAnimation {
    from {
        -webkit-filter: invert(0%);
                filter: invert(0%);
        background-color: transparent;
    }
    to {
        -webkit-filter: invert(100%);
                filter: invert(100%);
        background-color: black;
    }
}

@-webkit-keyframes revertAllExperiencesLinkAnimation {
    from {
        background-color: black;
        -webkit-filter: invert(100%);
                filter: invert(100%);
    }
    to {
        background-color: transparent;
        -webkit-filter: invert(0%);
                filter: invert(0%);
    }
}

@keyframes revertAllExperiencesLinkAnimation {
    from {
        background-color: black;
        -webkit-filter: invert(100%);
                filter: invert(100%);
    }
    to {
        background-color: transparent;
        -webkit-filter: invert(0%);
                filter: invert(0%);
    }
}

@media screen and (max-width: 1300px) {
    #verticalBar {
        display: none;
    }
    #experiencesContainer {
        padding-left: unset;
        margin-top: unset;
        margin-bottom: 20px;
    }
}
.projectCardWrapper {
    width: 30%;
    height: 45%;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
}

#projectsContainer {
    height: 60vh;
}

.projectCard {
    background-color: orange;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: all 1s ease;
    border-radius: 15px;
}

.projectFront {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform: rotateX(0deg);
    display: flex;
}

.projectName {
    font-size: 1.25vw;
    padding: 10px;
    border-bottom: 2px solid white;
}

.projectDescription {
    /* margin-top: 5px; */
    text-align: justify;
    padding: 15px;
}

.projectCard>.back {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotateX(0deg) rotateY(180deg);
    backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    display: flex;
    flex-direction: column;
}

.projectLink {
    color: white;
    text-decoration: none;
    border: 2px solid white;
    padding: 3px;
    font-size: larger;
    margin-top: auto;
    margin-bottom: 5%;
}

@media screen and (max-device-width: 1300px) {
    .projectCardWrapper {
        width: 100%;
        height: 30vh;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .projectCard {
        background-color: orange;
        width: 100%;
        height: 100%;
        transform-style: preserve-3d;
        transition: all 1s ease;
        border-radius: 5px;
    }
    .projectCard>.front {
        display: flex;
        flex-direction: column;
    }
    .projectName {
        font-size: 125%;
        padding: 5px;
        margin: 0;
    }
    .projectDescription {
        margin-top: 0;
    }
}
#projectsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10vh;
    height: 60vh;
    /* border: 1px solid black; */
    /* height: 70vh; */
}

#allProjectsLink {
    font-size: x-large;
    /* margin-bottom: 10vh; */
}

#allProjectsLinkContainer {
    border: 1px solid white;
    padding: 10px;
    display: inline;
    -webkit-animation: revertAllProjectsLinkAnimation .5s forwards;
            animation: revertAllProjectsLinkAnimation .5s forwards;
}

#allProjectsLinkContainer:hover {
    -webkit-animation: invertAllProjectsLinkAnimation .5s forwards;
            animation: invertAllProjectsLinkAnimation .5s forwards;
}

@-webkit-keyframes invertAllProjectsLinkAnimation {
    from {
        -webkit-filter: invert(0%);
                filter: invert(0%);
        background-color: black;
    }
    to {
        -webkit-filter: invert(100%);
                filter: invert(100%);
        background-color: black;
    }
}

@keyframes invertAllProjectsLinkAnimation {
    from {
        -webkit-filter: invert(0%);
                filter: invert(0%);
        background-color: black;
    }
    to {
        -webkit-filter: invert(100%);
                filter: invert(100%);
        background-color: black;
    }
}

@-webkit-keyframes revertAllProjectsLinkAnimation {
    from {
        background-color: black;
        -webkit-filter: invert(100%);
                filter: invert(100%);
    }
    to {
        background-color: transparent;
        -webkit-filter: invert(0%);
                filter: invert(0%);
    }
}

@keyframes revertAllProjectsLinkAnimation {
    from {
        background-color: black;
        -webkit-filter: invert(100%);
                filter: invert(100%);
    }
    to {
        background-color: transparent;
        -webkit-filter: invert(0%);
                filter: invert(0%);
    }
}

@media screen and (max-width: 1300px) {
    #projectsContainer {
        height: auto;
        height: initial;
        margin-top: unset;
        margin-bottom: 20px;
    }
}
nav {
    position: fixed;
    background-color: rgb(0, 0, 0, .2);
    z-index: 1;
}

.navItem>a, .navItem>button {
    text-decoration: none;
    font-size: x-large;
    text-shadow: 5px 5px 5px black;
    text-transform: uppercase;
    color: rgb(255, 255, 255, 1);
    font-weight: 300;
    -webkit-filter: brightness(80%);
            filter: brightness(80%);
    -webkit-animation: dimNavLinks .5s forwards;
            animation: dimNavLinks .5s forwards;
}

.navItem>.active {
    text-decoration: underline;
    -webkit-animation: brightenNavLinks 1s forwards;
            animation: brightenNavLinks 1s forwards;
    /* filter: brightness(150%); */
}

.navItem:hover a:not(.active), .navItem:hover button:not(.active) {
    cursor: pointer;
    -webkit-animation: brightenNavLinks 1s forwards;
            animation: brightenNavLinks 1s forwards;
}

@-webkit-keyframes brightenNavLinks {
    front {
        -webkit-filter: brightness(80%);
                filter: brightness(80%);
    }
    to {
        -webkit-filter: brightness(150%);
                filter: brightness(150%);
    }
}

@keyframes brightenNavLinks {
    front {
        -webkit-filter: brightness(80%);
                filter: brightness(80%);
    }
    to {
        -webkit-filter: brightness(150%);
                filter: brightness(150%);
    }
}

@-webkit-keyframes dimNavLinks {
    from {
        -webkit-filter: brightness(150%);
                filter: brightness(150%);
    }
    to {
        -webkit-filter: brightness(80%);
                filter: brightness(80%);
    }
}

@keyframes dimNavLinks {
    from {
        -webkit-filter: brightness(150%);
                filter: brightness(150%);
    }
    to {
        -webkit-filter: brightness(80%);
                filter: brightness(80%);
    }
}
#homeViewNavBarContainer {
    width: 15vw;
    left: -15vw;
    display: flex;
    align-items: center;
    height: 100vh;
}

#homeViewNavBarItems {
    list-style: none;
    padding: 0;
    margin: auto;
    text-align: center;
}

#homeViewNavBarItems li+li {
    margin-top: 10vh;
}

#homeViewNavBarContainer.show {
    left: 0;
    transition: all .5s linear;
}

#homeViewNavBarContainer.hide {
    left: -15vw;
    transition: all .5s linear;
}

.homeViewNavBarLink {
    all: unset;
}

.homeViewNavBarLink:hover {
    cursor: pointer;
}

@media screen and (max-width: 1300px) {
    #homeViewNavBarContainer {
        /* display: none; */
        width: 50vw;
        left: -50vw;
        display: flex;
        align-items: center;
        height: 105vh;
        background-color: rgb(0, 0, 0, .8);
    }
    #homeViewNavBarContainer.show {
        left: 0;
        transition: all .5s linear;
    }
    #homeViewNavBarContainer.hide {
        left: -50vw;
        transition: all .5s linear;
    }
}
.skillCardWrapper {
    width: 6vw;
    min-width: 200px;
    height: 115px;
    /* min-width: 100px; */
    margin-top: 2%;
    margin-left: .25%;
    margin-right: .25%;
}

.skillCard {
    width: 100%;
    height: 100%;
    background-color: orange;
    display: flex;
    border-radius: 7px;
}

.skillCard>.front {
    font-size: 1vw;
}

.skillCard>.back {
    justify-items: center;
}

.card {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: all 1s;
}

.cardWrapper:hover>.card {
    transform: rotateY(180deg);
}

.card .front {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform: rotateX(0deg);
    display: flex;
}

.skillName {
    font-size: 150%;
}

.card .back {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotateX(0deg) rotateY(180deg);
    backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    display: flex;
    flex-direction: column;
}

.skillLink {
    color: white;
    text-decoration: none;
    border: 2px solid white;
    padding: 3px;
    /* margin-top: 10%; */
    font-size: larger;
    position: absolute;
    bottom: 5%;
    text-align: center;
    transform: translateX(-50%);
}

@media screen and (max-width: 1300px) {
    .skillCardWrapper {
        width: 150px;
        min-width: unset;
        height: 50px;
    }
    .skillName {
        font-size: 20px;
    }
    .cardWrapper:hover>.card {
        transform: unset;
    }
}
#nonTechSkillsContainer {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

#nonTechSkillsContainer>h3 {
    margin-top: 2vh;
    font-size: 1.5vw;
    text-decoration: underline;
}

#nonTechSkillsContainer {
    margin-top: 0;
    width: 90%;
    max-width: 825px;
}

#nonTechSkillsCategoryContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* border: 1px solid black; */
    /* height: 70vh; */
    justify-content: space-evenly;
}

@media screen and (max-width: 1300px) {
    #nonTechSkillsContainer {
        margin-top: 0;
        width: unset;
        max-width: unset;
    }
    #nonTechSkillsCategoryContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-evenly;
    }
    #nonTechSkillsContainer>h3 {
        margin-top: 2vh;
        font-size: 25px;
        text-decoration: underline;
    }
}
#techSkillsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* border: 1px solid black; */
    /* height: 70vh; */
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

#techSkillsContainer {
    padding-top: 0;
    margin: auto;
    margin-top: 0;
}

svg {
    margin-top: 0;
    padding-top: 0;
}

@media screen and (max-width: 1300px) {}
.techSkillChartContainer {
    width: 45%;
    margin-top: 3%;
}

@media screen and (max-width: 1300px) {
    .techSkillChartContainer {
        width: 100%;
        margin-top: 3%;
    }
}
#projectsViewIntroSection {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* background-image: url('../../res/images/auroraBorealis.jpg');
    background-size: cover; */
    /* background-position: 10%; */
    /* background-position: center bottom; */
}

#projectsViewIntroSection>h1 {
    font-size: 4vw;
}

.projectViewArrow {
    cursor: pointer;
    border: solid white;
    display: inline-block;
    /* margin-left: 5vw; */
    padding: 7px;
    -webkit-animation: arrowPulse 1.5s infinite;
            animation: arrowPulse 1.5s infinite;
}

.projectArrowContainer {
    position: absolute;
    left: 50%;
    bottom: 5vh;
    border-width: 4px 0 0 4px;
}
.detailedProjectCardContainer {
    width: 30vw;
    height: 40vh;
    margin: 2vh;
    border-radius: 10px;
    /* width: max-content;
    height: max-content; */
    background-color: #5c2756;
}

.detailedProjectCard {
    position: relative;
    color: white;
    width: 100%;
    height: 100%;
}

.detailedProjectCardInfo {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.detailedProjectCardProjectName {
    font-size: 2vw;
}

.detailedProjectCard>p {
    font-size: 1vw;
}

.detailedProjectCardClickablesContainer {
    position: absolute;
    bottom: 10px;
    width: 100%;
    display: flex;
}

.detailedProjectCardClickablesContainer button, .detailedProjectCardClickablesContainer a {
    -webkit-animation: none 0s ease 0s 1 normal none running;
            animation: none 0s ease 0s 1 normal none running;
    -webkit-backface-visibility: visible;
            backface-visibility: visible;
    background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
    border: medium none currentColor;
    border-collapse: separate;
    border-image: none;
    border-radius: 0;
    border-spacing: 0;
    bottom: auto;
    box-shadow: none;
    box-sizing: content-box;
    caption-side: top;
    clear: none;
    clip: auto;
    color: #000;
    -webkit-columns: auto;
            columns: auto;
    -webkit-column-count: auto;
            column-count: auto;
    -webkit-column-fill: balance;
            column-fill: balance;
    grid-column-gap: normal;
    -webkit-column-gap: normal;
            column-gap: normal;
    -webkit-column-rule: medium none currentColor;
            column-rule: medium none currentColor;
    -webkit-column-span: 1;
            column-span: 1;
    -webkit-column-width: auto;
            column-width: auto;
    content: normal;
    counter-increment: none;
    counter-reset: none;
    cursor: auto;
    direction: ltr;
    display: inline;
    empty-cells: show;
    float: none;
    font-family: serif;
    font-size: medium;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    height: auto;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    left: auto;
    letter-spacing: normal;
    list-style: disc outside none;
    margin: 0;
    max-height: none;
    max-width: none;
    min-height: 0;
    min-width: 0;
    opacity: 1;
    orphans: 2;
    outline: medium none invert;
    overflow: visible;
    overflow-x: visible;
    overflow-y: visible;
    padding: 0;
    page-break-after: auto;
    page-break-before: auto;
    page-break-inside: auto;
    perspective: none;
    perspective-origin: 50% 50%;
    position: static;
    right: auto;
    -moz-tab-size: 8;
         tab-size: 8;
    table-layout: auto;
    text-align: left;
    text-align-last: auto;
    text-decoration: none;
    text-indent: 0;
    text-shadow: none;
    text-transform: none;
    top: auto;
    transform: none;
    transform-origin: 50% 50% 0;
    transform-style: flat;
    transition: none 0s ease 0s;
    unicode-bidi: normal;
    vertical-align: baseline;
    visibility: visible;
    white-space: normal;
    widows: 2;
    width: auto;
    word-spacing: normal;
    z-index: auto;
    all: initial;
    width: 20%;
    text-align: center;
    font-size: x-large;
    color: white;
    border: 2px solid white;
    padding: 1%;
    vertical-align: middle;
    font-family: 'Source Sans Pro', sans-serif;
}

.detailedProjectCardClickablesContainer button {
    margin-right: auto;
    margin-left: auto;
}

.detailedProjectCardClickablesContainer a {
    margin-right: auto;
    margin-left: auto;
}

.detailedProjectCardClickablesContainer button:hover, .detailedProjectCardClickablesContainer a:hover {
    cursor: pointer;
    background-color: #005e6c;
}

.status>.ongoing {
    color: #29b0ac;
}

.status>.inProgress {
    color: #faa41a;
}

.status>.completed {
    color: #67973f;
}

.detailedProjectCardProjectName {
    margin-bottom: 0;
}

@media screen and (max-width: 1300px) {
    .detailedProjectCardContainer {
        width: 100vw !important;
        height: 50vh;
        border-radius: 10px;
        background-color: #5c2756;
        margin: 25px 0;
    }
    .detailedProjectCard {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .detailedProjectCardProjectName {
        font-size: 20px;
    }
    .detailedProjectCard>p {
        font-size: 12px;
    }
    .detailedProjectCardClickablesContainer button, .detailedProjectCardClickablesContainer a {
        font-size: large;
        padding: 1%;
    }
}
#projectsSectionContainer {
    margin: auto;
    margin-bottom: 100px;
}

#tagSearchBar {
    margin-top: 25px;
    margin-bottom: 25px;
    width: 30%;
    height: 1.5vw;
    font-size: 1.2vw;
}

#projectsSectionContainer>h2 {
    font-size: xx-large;
}

#allTagsContainer {
    margin: auto;
    width: 60%;
}

.tagsContainer {
    /* padding-top: 10px; */
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.tagTitle {
    margin-top: auto;
    margin-bottom: auto;
    font-weight: bold;
    font-size: 24px;
    display: inline;
}

.tag {
    padding: 10px;
    border: 1px solid white;
    margin: 5px;
    min-width: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* width: 0px; */
}

.tag.selectedTag {
    background-color: #67973f;
}

.tag:hover {
    cursor: pointer;
    background-color: #67973f;
}

.detailedProjectCardContainer.hide {
    -webkit-animation: hideProject 1s forwards;
            animation: hideProject 1s forwards;
    /* display: none; */
    /* flex-grow: .000001; */
    /* transition: all 1s; */
}

.iframeContainer {
    width: 95vw;
    position: fixed;
    left: 50%;
    top: 35%;
    transform: translateX(-50%) translateY(-40%);
    height: 0;
    opacity: 0;
    background-color: white;
    z-index: 5
}

.iframeContainer>.x {
    position: absolute;
    top: -4%;
    left: 2%;
    color: blue;
    font-weight: 900;
    font-size: xx-large;
}

.iframeContainer>.x:hover {
    cursor: pointer;
}

.iframeContainer.selected {
    -webkit-animation: showSample 1s forwards;
            animation: showSample 1s forwards;
}

.iframeContainer.unselected {
    -webkit-animation: hideSample 1s forwards;
            animation: hideSample 1s forwards;
}

.sample {
    height: 100%;
    width: 100%;
    z-index: 5;
    /* width: 100vw; */
}

.detailedProjectCardContainer.show {
    /* display: block; */
    /* flex-grow: 1; */
    -webkit-animation: showProject 1s forwards;
            animation: showProject 1s forwards;
}

#detailedProjectCardsContainer {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
}

#autocompleteContainer {
    position: relative;
    width: 200px;
    margin: auto;
}

#autocompleteContainer>input {
    width: 100%;
    margin: 25px auto 50px auto;
    height: 20px;
}

#autocompleteContainer>input:focus {
    outline: none;
}

#autocompleteContainer>::-webkit-scrollbar {
    width: 8px;
}

#autocompleteContainer>::-webkit-scrollbar-thumb {
    background-color: #979696;
    border-radius: 100px;
    width: 10px;
    background-clip: padding-box;
    border-left: 1px solid white;
    border-right: 1px solid white;
    z-index: -1;
}

#autocompleteResultsContainer {
    position: absolute;
    background-color: white;
    color: black;
    max-height: 150px;
    overflow-y: scroll;
    left: 50%;
    transform: translateX(calc(-50% + 4px)) translateY(-50px);
    width: calc(100% + 6px);
    padding: 0;
    margin-top: 1px;
    list-style-type: none;
    z-index: 100;
}

.autocompleteResult:hover {
    background-color: #E7E3E2;
    cursor: pointer;
}

.autocompleteResult {
    all: unset;
    display: block;
    padding-top: 3px;
    padding-bottom: 3px;
    width: 100%;
}

@-webkit-keyframes showSample {
    0% {
        height: 0;
        opacity: 0;
    }
    100% {
        opacity: 1;
        height: 80vh;
    }
}

@keyframes showSample {
    0% {
        height: 0;
        opacity: 0;
    }
    100% {
        opacity: 1;
        height: 80vh;
    }
}

@-webkit-keyframes hideSample {
    0% {
        /* display: block; */
        height: 80vh;
        opacity: 1;
    }
    100% {
        opacity: 0;
        visibility: hidden;
        height: 0;
        line-height: 0;
        overflow: hidden;
    }
}

@keyframes hideSample {
    0% {
        /* display: block; */
        height: 80vh;
        opacity: 1;
    }
    100% {
        opacity: 0;
        visibility: hidden;
        height: 0;
        line-height: 0;
        overflow: hidden;
    }
}

@-webkit-keyframes showProject {
    from {
        opacity: 0;
        /* width: 0; */
        /* flex-grow: .01; */
    }
    to {
        opacity: 1;
        /* width: initial; */
        /* flex-grow: 1; */
    }
}

@keyframes showProject {
    from {
        opacity: 0;
        /* width: 0; */
        /* flex-grow: .01; */
    }
    to {
        opacity: 1;
        /* width: initial; */
        /* flex-grow: 1; */
    }
}

@-webkit-keyframes hideProject {
    from {
        /* flex-grow: 1; */
        /* width: initial; */
        opacity: 1;
    }
    to {
        opacity: 0;
        /* width: 0 */
        /* flex-grow: .1; */
    }
}

@keyframes hideProject {
    from {
        /* flex-grow: 1; */
        /* width: initial; */
        opacity: 1;
    }
    to {
        opacity: 0;
        /* width: 0 */
        /* flex-grow: .1; */
    }
}

@media screen and (max-width: 1300px) {
    #allTagsContainer {
        width: 100%;
    }
    .tag {
        padding: 2px 5px;
        border: 1px solid white;
        margin: 5px;
        min-width: 10%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* width: 0px; */
    }
    .tagTitle {
        font-weight: bold;
        font-size: 20px;
        width: 100%;
        margin-top: 10px;
    }
    #detailedProjectCardsContainer {
        width: 100vw;
    }
}
#projectsView {
    width: 100vw;
    color: white;
    text-align: center;
}
#experienceViewIntroSection {
    height: 100vh;
    width: 100vw;
    /* background-image: url('../../res/images/RainierCompressed.jpg');
    background-size: cover; */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#experienceViewIntroSection>h1 {
    /* position: absolute;
    top: 40%; */
    font-size: 4vw;
}
.detailedExperienceCardContainer {
    background-color: #29b0ac;
    width: 60%;
    margin: auto;
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
    /* padding: 2%; */
    margin-bottom: 5%;
}

.detailedExperienceCardHeader, .detailedExperienceCardFooter {
    display: flex;
    padding-top: 2%;
    padding-bottom: 2%;
}

.detailedExperienceCardBody {
    padding-left: 2.5%;
    padding-right: 2.5%;
    border-bottom: .5px solid gray;
    /* justify-content: space-evenly; */
}

.detailedExperienceCardFooter {
    border-top: .5px solid gray;
    /* justify-content: space-evenly; */
}

.detailedExperienceCardHeader>.detailedExperienceCardCompanyLogo {
    margin-left: 5%;
    margin-right: auto;
}

.detailedExperienceCardHeader {
    border-bottom: .5px solid gray;
    text-transform: uppercase;
    /* justify-content: space-evenly; */
}

.detailedExperienceCardHeader>.detailedExperienceCardCompanyName {
    margin-left: 50%;
    transform: translateX(-50%);
    margin-right: auto;
    font-size: 1.25vw;
    text-align: center;
}

.detailedExperienceCardHeader>.detailedExperienceCardCompanyRole {
    margin-left: auto;
    margin-right: 5%;
    margin-top: auto;
    text-align: right;
}

.detailedExperienceCardFooter>.detailedExperienceCardCompanyWorkDuration {
    margin-left: 2.5%;
    /* margin-right: auto; */
}

.detailedExperienceCardFooter>.detailedExperienceCardCompanyWorkLocation {
    margin-left: auto;
    margin-right: 2.5%;
}

@media screen and (max-width: 1300px) {
    .experienceTypeHeading {
        text-align: center !important;
        margin-left: 0 !important;
    }
    .detailedExperienceCardContainer {
        width: 100%;
        margin: auto;
        text-align: left;
        padding-left: 0px;
        padding-right: 0px;
        /* padding: 2%; */
        margin-bottom: 5%;
    }
    .detailedExperienceCardHeader {
        border-bottom: .5px solid gray;
        text-transform: uppercase;
        /* justify-content: space-evenly; */
        text-align: center;
        flex-direction: column;
    }
    .detailedExperienceCardHeader>.detailedExperienceCardCompanyName {
        margin: unset;
        transform: translateX(0%);
        font-size: 20px;
        text-align: center;
    }
    .detailedExperienceCardHeader>.detailedExperienceCardCompanyRole {
        margin: unset;
        text-align: center;
    }
}
#experienceSectionContainer {
    margin-bottom: 100px;
    /* width: 80%; */
    /* margin: auto; */
}

#experienceViewNavBar {
    all: unset;
}

#experienceViewNavItems {
    list-style-type: none;
    display: flex;
    width: 30%;
    margin: auto;
    justify-content: space-between;
}

.experienceViewNavItem>button {
    background-color: transparent;
    border: none;
    text-decoration: none;
    color: white;
    font-size: 25px;
}

.experienceTypeHeading {
    font-size: 35px;
    text-align: left;
    margin-left: 20%;
    font-weight: normal;
}

@media screen and (max-width: 1300px) {
    #experienceViewNavItems {
        width: 100%;
        flex-direction: column;
        padding: 0;
    }
}
#experienceView {
    text-align: center;
    color: white;
}

.experienceViewArrow {
    cursor: pointer;
    border: solid white;
    display: inline-block;
    /* margin-left: 5vw; */
    padding: 7px;
    -webkit-animation: arrowPulse 1.5s infinite;
            animation: arrowPulse 1.5s infinite;
}

.experienceArrowContainer {
    position: absolute;
    left: 50%;
    bottom: 5vh;
    border-width: 4px 0 0 4px;
}
#contactViewIntroSection {
    height: 100vh;
    width: 100vw;
    /* background-image: url('../../res/images/rainier_reflection.jpg');
    background-size: cover; */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#contactViewIntroSection>h1 {
    /* position: absolute;
    top: 40%; */
    font-size: 4vw;
}
.contactCardContainer {
    margin: auto;
    width: 30%;
    min-width: 500px;
    height: 120px;
    background-color: #005e6c;
    box-shadow: 15px 15px 15px black;
    /* margin-bottom: 50px; */
}

.contactCard {
    width: 100%;
    height: 100%;
    text-align: left;
    display: flex;
    color: blue;
}

.contactDetailLogoContainer {
    /* display: inline-block; */
    height: 100%;
    width: 100px;
    background-position: center;
    border-radius: 100%;
    background-size: 80%;
    background-repeat: no-repeat;
    margin-left: 15px;
}

.contactDetailLogoContainer>img {
    height: 100%;
}

.contactCard>a {
    color: white;
    text-decoration: none;
}

.contactCard>a:visited {
    color: white;
}

.contactCard>a:last-child {
    margin: auto;
    text-decoration: none;
    font-size: 1.25vw;
}

.contactCard>a:last-child:hover {
    /* color: red; */
    text-decoration: underline;
}

@media screen and (max-width: 1300px) {
    .contactCardContainer {
        width: 90%;
        height: 100px;
        min-width: unset;
    }
    .contactDetailLogoContainer {
        /* display: inline-block; */
        height: 100%;
        width: 80px;
        background-size: 50%;
        margin-left: 0px;
    }
    .contactCard>a:last-child {
        font-size: 20px;
    }
}
body {
    background-color: #282c34;
    overflow-y: scroll;
}

* {
    font-family: 'Source Sans Pro', sans-serif;
}

html {
    -ms-scroll-snap-type: y proximity;
        scroll-snap-type: y proximity;
    margin-bottom: 150px;
}

h1 {
    text-shadow: 5px 5px 5px black;
}

h1:not(#name) {
    width: 50%;
}

section>div>div:first-of-type {
    margin-top: 5vh;
}

#introBackgroundContainer {
    height: 100vh;
    width: 100vw;
    position: absolute;
    z-index: -1;
    /* background-image: url("./res/images/mountain3.jpg"); */
    /* background-image: url("./res/images/me_wedding_compressed_2.jpg"); */
    /* background-size: 115%; */
    /* background-position: 0 15%; */
    background-image: url(/static/media/me_wedding_compressed_cropped_4.801d234e.jpg);
    background-size: 100%;
    background-position: 0 15%;
}

section {
    /* scroll-snap-align: start;
    scroll-snap-stop: normal; */
    /* height: 100vh; */
    padding: 1px;
}

.introSection {
    height: 100vh;
}

section>div {
    margin-top: 10vh !important;
}

#scrollToTopButtonWrapper {
    position: fixed;
    bottom: 3%;
    right: 5%;
    -webkit-animation: displayScrollToTopButtonAnimation 1.5s forwards;
            animation: displayScrollToTopButtonAnimation 1.5s forwards;
}

#scrollToTopButtonWrapper>button {
    all: unset;
}

.routerNavigationArrowContainer {
    top: 50%;
}

.navigationArrowContainer {
    position: absolute;
    z-index: 2;
    color: white;
    text-shadow: 2px 2px 2px black;
}

.navigationArrowContainer.fixed {
    position: fixed;
}

.fa-chevron-left, .fa-chevron-right, .fa-chevron-up, .fa-chevron-down {
    -webkit-animation: arrowPulse 2s infinite;
            animation: arrowPulse 2s infinite;
    -webkit-filter: drop-shadow(2px 2px 2px black);
            filter: drop-shadow(2px 2px 2px black);
    /* text-shadow: 2px 2px 2px black; */
}

.viewNavigationArrowContainer {
    left: 50vw;
}

#rightRouterNavigationArrowContainer {
    right: 50px;
}

#leftRouterNavigationArrowContainer {
    left: 50px;
}

.navigationArrowContainer:hover {
    cursor: pointer;
}

.routerNavigationArrowContainer>p {
    position: absolute;
    transform-origin: center;
    font-size: 20px;
    width: 120px;
    margin: 0;
    text-align: center;
    -webkit-animation: viewNamePulse 2s infinite;
            animation: viewNamePulse 2s infinite;
}

#rightRouterNavigationArrowContainer>p {
    transform: rotate(-90deg) translateY(-75px) translateY(-10px);
}

#leftRouterNavigationArrowContainer>p {
    transform: rotate(90deg) translateY(15px);
}

#scrollToTopButtonWrapper>button:hover {
    cursor: pointer;
}

.slide-right-enter {
    -webkit-animation: animateRightEntry .75s forwards;
            animation: animateRightEntry .75s forwards;
}

.slide-right-exit {
    -webkit-animation: animateLeftExit .75s forwards;
            animation: animateLeftExit .75s forwards;
}

.slide-left-enter {
    -webkit-animation: animateLeftEntry .75s forwards;
            animation: animateLeftEntry .75s forwards;
}

.slide-left-exit {
    -webkit-animation: animateRightExit .75s forwards;
            animation: animateRightExit .75s forwards;
}

#topLeftIcon {
    display: none;
}

[data-icon="arrow-circle-up"] {
    color: white;
}

@-webkit-keyframes arrowPulse {
    0% {
        opacity: .2;
        transform: scale(1);
    }
    50% {
        opacity: 1;
        transform: scale(1.3);
    }
    100% {
        opacity: .2;
        transform: scale(1);
    }
}

@keyframes arrowPulse {
    0% {
        opacity: .2;
        transform: scale(1);
    }
    50% {
        opacity: 1;
        transform: scale(1.3);
    }
    100% {
        opacity: .2;
        transform: scale(1);
    }
}

@-webkit-keyframes viewNamePulse {
    0% {
        opacity: .2;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: .2;
    }
}

@keyframes viewNamePulse {
    0% {
        opacity: .2;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: .2;
    }
}

@-webkit-keyframes animateRightEntry {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0%);
    }
}

@keyframes animateRightEntry {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0%);
    }
}

@-webkit-keyframes animateLeftExit {
    from {
        transform: translateX(0%);
    }
    to {
        transform: translateX(-100%);
    }
}

@keyframes animateLeftExit {
    from {
        transform: translateX(0%);
    }
    to {
        transform: translateX(-100%);
    }
}

@-webkit-keyframes animateLeftEntry {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0%);
    }
}

@keyframes animateLeftEntry {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0%);
    }
}

@-webkit-keyframes animateRightExit {
    from {
        transform: translateX(0%);
    }
    to {
        transform: translateX(100%);
    }
}

@keyframes animateRightExit {
    from {
        transform: translateX(0%);
    }
    to {
        transform: translateX(100%);
    }
}

@-webkit-keyframes displayScrollToTopButtonAnimation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes displayScrollToTopButtonAnimation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media screen and (max-width: 1300px) {
    .routerNavigationArrowContainer {
        display: none;
    }
    #introBackgroundContainer {
        top: 5%;
        height: 30%;
        width: 60%;
        left: 20%;
        background-image: url(/static/media/me_wedding_mobile.5324ece3.jpg);
        background-repeat: no-repeat;
        background-size: 150%;
        background-position: 50% 0%;
        border-radius: 100%;
        z-index: 1;
    }
    #topLeftIcon {
        z-index: 5;
        display: block;
        position: fixed;
        top: 15px;
        left: 15px;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        /* filter: drop-shadow(6px 6px 6px solid black); */
    }
    h1 {
        width: 100% !important;
        font-size: 50px !important;
    }
    section>div:not(#introSection) {
        margin-top: unset !important;
    }
}
.aboutMeCardContainer {
    height: 30vh;
    width: 45%;
    background-color: #67973f;
    text-align: left;
    box-shadow: 10px 10px 10px black;
    margin-bottom: 3%;
}

.aboutMeCardContent>p {
    margin-left: 5%;
    margin-right: 5%;
}

.aboutMeCardQuestionWrapper {
    background-color: rgb(0, 0, 0, .2);
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.aboutMeCardQuestion {
    padding: 2% 4%;
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    color: black;
}

@media screen and (max-device-width: 1300px) {
    .aboutMeCardContainer {
        width: 80%;
        height: 40vh;
    }
}
#aboutMeViewIntroSection {
    height: 100vh;
    width: 100vw;
    /* background-image: url('../../res/images/rainier_reflection.jpg');
    background-size: cover; */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#aboutMeViewIntroSection>h1 {
    /* position: absolute;
    top: 40%; */
    font-size: 4vw;
}
#detailedAboutMeContainer {
    width: 60vw;
    margin: auto;
}

#aboutMeCardsContainer {
    display: flex;
    flex: row 1;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 5%;
}

#descriptionCards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    flex-basis: 20%;
    margin: 0;
}

#faqHeading {
    font-size: 2vw;
}

#aboutMeSectionContainer {
    margin-bottom: 100px;
}

@media screen and (max-device-width: 1300px) {
    #detailedAboutMeContainer {
        width: 100vw;
    }
    #faqHeading {
        font-size: 30px;
    }
}
.descriptionCard {
    width: 100%;
    height: 100%;
}

.descriptionCardWrapper {
    width: 8vw;
    height: 8vw;
    position: relative;
}

.descriptionCardText {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    z-index: 1;
}

.hexagonTriangleTop {
    float: left;
    border-right: 30px solid #6C6;
    border-top: 52px solid transparent;
    border-bottom: 52px solid transparent;
}

.hexagonContainer {
    width: 100%;
    height: 100%;
}

.hexagonRectangle {
    float: left;
    width: 60px;
    height: 104px;
}

.hexagonTriangleBottom {
    float: left;
    border-left: 30px solid #6C6;
    border-top: 52px solid transparent;
    border-bottom: 52px solid transparent;
}

.hexagon {
    /*background-color: #04c2c9;*/
    background-color: #29b0ac;
    /*background-color: #209794;*/
    display: inline-block;
    height: 100%;
    width: calc(100% * 0.57735);
}

.hexagon::before, .hexagon::after {
    background-color: inherit;
    content: "";
    height: inherit;
    position: absolute;
    right: calc((100% / 2) - ((100% * 0.57735) / 2));
    top: 0;
    width: inherit;
}

.hexagon::before {
    transform: rotateZ(60deg);
}

.hexagon::after {
    transform: rotateZ(-60deg);
}

.descriptionWord {
    /* height: 100%; */
    position: absolute;
    top: 30%;
    z-index: 1;
    font-size: 1.15vw;
    width: 100%;
    text-transform: uppercase;
    /* text-align: center; */
}

.descriptionWordText {
    margin-top: 15px;
    font-size: larger;
    margin-left: -15%;
    width: 130%;
}

[data-aos="rotate"] {
    transform: rotate(-1080deg);
    transition-property: transform;
}

[data-aos="rotate"].aos-animate {
    transform: rotate(0deg);
}

@media screen and (max-device-width: 1300px) {
    .descriptionCardWrapper {
        width: 25vw;
        height: 25vw;
        margin: 10px;
    }
    .descriptionWord {
        height: 100%;
        top: 0%;
        margin: 0%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 15px;
    }
}
#selfImage {
    /* width: 30%; */
    /* border-radius: 100%;
    display: inline-block;
    margin: 0; */
}

.aboutMeText {
    font-size: larger;
    margin: auto;
    width: 50%;
}

/* .aboutMeTextContainer {
    display: flex;
    flex-direction: column;
} */

#aboutCountainer {
    /* margin-top: 0; */
}

#aboutMeDescriptionContainer {
    padding-top: 15vh;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#descriptionCards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    flex-basis: 20%;
    margin: 0;
}

.meImage {
    overflow: hidden;
    visibility: hidden;
    transform: rotate(120deg);
    width: 25vw;
    height: 25vh;
    /* margin-bottom: 5vh; */
}

.meImage-in1 {
    overflow: hidden;
    width: 100%;
    height: 100%;
    transform: rotate(-60deg);
}

.meImage-in2 {
    width: 100%;
    height: 100%;
    /* background-repeat: no-repeat; */
    background-size: contain;
    background-position: 50%;
    background-color: black;
    background-image: url(/static/media/Me.1a6c5cb9.jfif);
    /* background-image: url(http://placekitten.com/240/240); */
    visibility: visible;
    transform: rotate(-60deg);
}

#moreAboutMeLink {
    font-size: x-large;
    /* margin-bottom: 10vh; */
}

#moreAboutMeLinkContainer {
    border: 1px solid white;
    padding: 10px;
    display: inline;
    -webkit-animation: revertLinkAnimation .5s forwards;
            animation: revertLinkAnimation .5s forwards;
}

#moreAboutMeLinkContainer:hover {
    -webkit-animation: invertLinkAnimation .5s forwards;
            animation: invertLinkAnimation .5s forwards;
}

@-webkit-keyframes invertLinkAnimation {
    from {
        -webkit-filter: invert(0%);
                filter: invert(0%);
        background-color: transparent;
    }
    to {
        -webkit-filter: invert(100%);
                filter: invert(100%);
        background-color: black;
    }
}

@keyframes invertLinkAnimation {
    from {
        -webkit-filter: invert(0%);
                filter: invert(0%);
        background-color: transparent;
    }
    to {
        -webkit-filter: invert(100%);
                filter: invert(100%);
        background-color: black;
    }
}

@-webkit-keyframes revertLinkAnimation {
    from {
        background-color: black;
        -webkit-filter: invert(100%);
                filter: invert(100%);
    }
    to {
        background-color: transparent;
        -webkit-filter: invert(0%);
                filter: invert(0%);
    }
}

@keyframes revertLinkAnimation {
    from {
        background-color: black;
        -webkit-filter: invert(100%);
                filter: invert(100%);
    }
    to {
        background-color: transparent;
        -webkit-filter: invert(0%);
                filter: invert(0%);
    }
}
#skillsContainer, #techSkillsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* border: 1px solid black; */
    /* height: 70vh; */
    justify-content: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-bottom: 10vh;
}

#techSkillsContainer {
    padding-top: 0;
    margin: auto;
}

#homeSkillsSection {
    padding-top: 10vh;
}
#topNavBarContainer {
    padding-top: 1vh;
    padding-bottom: 1vh;
    width: 100vw;
    right: 0;
}

#topNavBarItems {
    width: 40%;
    margin-right: 15px;
    margin-left: auto;
    list-style: none;
    display: flex;
    justify-content: space-evenly;
}

@media screen and (max-width: 1300px) {
    #topNavBarContainer {
        display: none;
    }
}
#aboutMeView {
    text-align: center;
    color: white;
}
#contactSectionContainer {
    /* width: 80%; */
    margin: auto;
    height: 100vh;
    width: 100vw;
}

#contactCardContainer {
    display: flex;
    height: 65vh;
    flex-direction: column;
    justify-content: space-between;
}
#contactView {
    text-align: center;
    color: white;
}

.contactViewArrow {
    cursor: pointer;
    border: solid white;
    display: inline-block;
    /* margin-left: 5vw; */
    padding: 7px;
    -webkit-animation: arrowPulse 1.5s infinite;
            animation: arrowPulse 1.5s infinite;
}

.contactArrowContainer {
    position: absolute;
    left: 50%;
    bottom: 5vh;
    border-width: 4px 0 0 4px;
}


