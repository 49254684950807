.contactCardContainer {
    margin: auto;
    width: 30%;
    min-width: 500px;
    height: 120px;
    background-color: #005e6c;
    box-shadow: 15px 15px 15px black;
    /* margin-bottom: 50px; */
}

.contactCard {
    width: 100%;
    height: 100%;
    text-align: left;
    display: flex;
    color: blue;
}

.contactDetailLogoContainer {
    /* display: inline-block; */
    height: 100%;
    width: 100px;
    background-position: center;
    border-radius: 100%;
    background-size: 80%;
    background-repeat: no-repeat;
    margin-left: 15px;
}

.contactDetailLogoContainer>img {
    height: 100%;
}

.contactCard>a {
    color: white;
    text-decoration: none;
}

.contactCard>a:visited {
    color: white;
}

.contactCard>a:last-child {
    margin: auto;
    text-decoration: none;
    font-size: 1.25vw;
}

.contactCard>a:last-child:hover {
    /* color: red; */
    text-decoration: underline;
}

@media screen and (max-width: 1300px) {
    .contactCardContainer {
        width: 90%;
        height: 100px;
        min-width: unset;
    }
    .contactDetailLogoContainer {
        /* display: inline-block; */
        height: 100%;
        width: 80px;
        background-size: 50%;
        margin-left: 0px;
    }
    .contactCard>a:last-child {
        font-size: 20px;
    }
}