.techSkillChartContainer {
    width: 45%;
    margin-top: 3%;
}

@media screen and (max-width: 1300px) {
    .techSkillChartContainer {
        width: 100%;
        margin-top: 3%;
    }
}