.projectCardWrapper {
    width: 30%;
    height: 45%;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
}

#projectsContainer {
    height: 60vh;
}

.projectCard {
    background-color: orange;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: all 1s ease;
    border-radius: 15px;
}

.projectFront {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform: rotateX(0deg);
    display: flex;
}

.projectName {
    font-size: 1.25vw;
    padding: 10px;
    border-bottom: 2px solid white;
}

.projectDescription {
    /* margin-top: 5px; */
    text-align: justify;
    padding: 15px;
}

.projectCard>.back {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotateX(0deg) rotateY(180deg);
    backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    display: flex;
    flex-direction: column;
}

.projectLink {
    color: white;
    text-decoration: none;
    border: 2px solid white;
    padding: 3px;
    font-size: larger;
    margin-top: auto;
    margin-bottom: 5%;
}

@media screen and (max-device-width: 1300px) {
    .projectCardWrapper {
        width: 100%;
        height: 30vh;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .projectCard {
        background-color: orange;
        width: 100%;
        height: 100%;
        transform-style: preserve-3d;
        transition: all 1s ease;
        border-radius: 5px;
    }
    .projectCard>.front {
        display: flex;
        flex-direction: column;
    }
    .projectName {
        font-size: 125%;
        padding: 5px;
        margin: 0;
    }
    .projectDescription {
        margin-top: 0;
    }
}