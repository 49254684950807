#aboutMeViewIntroSection {
    height: 100vh;
    width: 100vw;
    /* background-image: url('../../res/images/rainier_reflection.jpg');
    background-size: cover; */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#aboutMeViewIntroSection>h1 {
    /* position: absolute;
    top: 40%; */
    font-size: 4vw;
}