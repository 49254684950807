.skillCardWrapper {
    width: 6vw;
    min-width: 200px;
    height: 115px;
    /* min-width: 100px; */
    margin-top: 2%;
    margin-left: .25%;
    margin-right: .25%;
}

.skillCard {
    width: 100%;
    height: 100%;
    background-color: orange;
    display: flex;
    border-radius: 7px;
}

.skillCard>.front {
    font-size: 1vw;
}

.skillCard>.back {
    justify-items: center;
}

.card {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: all 1s;
}

.cardWrapper:hover>.card {
    transform: rotateY(180deg);
}

.card .front {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform: rotateX(0deg);
    display: flex;
}

.skillName {
    font-size: 150%;
}

.card .back {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotateX(0deg) rotateY(180deg);
    backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    display: flex;
    flex-direction: column;
}

.skillLink {
    color: white;
    text-decoration: none;
    border: 2px solid white;
    padding: 3px;
    /* margin-top: 10%; */
    font-size: larger;
    position: absolute;
    bottom: 5%;
    text-align: center;
    transform: translateX(-50%);
}

@media screen and (max-width: 1300px) {
    .skillCardWrapper {
        width: 150px;
        min-width: unset;
        height: 50px;
    }
    .skillName {
        font-size: 20px;
    }
    .cardWrapper:hover>.card {
        transform: unset;
    }
}