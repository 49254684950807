#introSection {
    /* width: 100%; */
    top: 10vh;
    padding-right: 40%;
}

#descriptionContainer {
    height: fit-content;
    width: 100%;
}

#description {
    font-size: 1.25vw;
    width: 60%;
    text-shadow: 0px 0px 5px black, 5px 5px 5px black;
}

#name {
    font-size: 5vw;
    /* font-family: casanova; */
    /* margin-top: 20vh; */
    margin-bottom: 2vh;
    font-weight: 400;
    text-transform: uppercase;
}

#title {
    font-size: 3vw;
    margin-bottom: 3vh;
    font-weight: 650;
    /* font-family: casanova; */
}

#homeResourcesContainer {
    all: initial;
    margin: auto;
    margin-top: 50px;
    display: flex;
    width: 60%;
    justify-content: space-between;
    flex-direction: row;
}

.homeResource {
    padding: 1%;
    width: 20%;
    display: flex;
    text-decoration: none;
    animation: removeBackgroundAnimation .5s forwards;
    filter: drop-shadow(5px 5px 4px black) drop-shadow(1px 1px 0 black)
}

.homeResource+.homeResource {
    margin-left: 5%;
}

.homeResource>p {
    font-size: 25px;
}

.homeResource:hover {
    animation: addBackgroundAnimation .5s forwards;
}

.homeResource>p {
    margin-right: auto;
}

.homeResource>svg {
    margin-left: auto;
}

.homeResource>p, .homeResource>svg {
    animation: revertResourceAnimation .5s forwards;
    /* animation-fill-mode: ; */
}

.homeResource:hover>p, .homeResource:hover>svg {
    animation: invertResourceAnimation .5s both;
    /* filter: invert(100%); */
}

@keyframes pulse {
    0% {
        transform: scale(1) rotate(45deg);
    }
    50% {
        transform: scale(1.3) rotate(45deg);
    }
    100% {
        transform: scale(1) rotate(45deg);
        /* transition */
    }
}

@keyframes invertResourceAnimation {
    0% {
        filter: invert(0%);
        /* filter: drop-shadow(10px 10px 5px black) */
    }
    100% {
        filter: invert(100%);
        /* filter: drop-shadow(10px 10px 5px white) */
    }
}

@keyframes revertResourceAnimation {
    0% {
        filter: invert(100%);
        /* filter: drop-shadow(10px 10px 5px white) */
    }
    100% {
        filter: invert(0%);
        /* filter: drop-shadow(10px 10px 5px black) */
    }
}

@keyframes addBackgroundAnimation {
    0% {
        /* background-color: transparent; */
    }
    100% {
        background-color: white;
    }
}

@keyframes removeBackgroundAnimation {
    0% {
        background-color: white;
    }
    100% {
        /* background-color: transparent; */
    }
}

@media screen and (max-width: 1300px) {
    #introSection {
        padding-right: unset;
        top: 20%;
    }
    #description {
        font-size: 20px;
        width: 100%;
    }
    #name {
        font-size: 50px;
        margin-bottom: 10px;
        font-weight: 400;
        text-transform: uppercase;
    }
    #title {
        font-size: 30px !important;
        margin-bottom: 15px;
        font-weight: 650;
    }
    #homeResourcesContainer {
        margin-top: 50px;
        width: 100%;
        flex-direction: column;
    }
    .homeResource {
        margin-top: 10px;
        width: 40%;
    }
    .homeResource+.homeResource {
        margin-left: auto;
    }
}