#nonTechSkillsContainer {
    height: fit-content;
}

#nonTechSkillsContainer>h3 {
    margin-top: 2vh;
    font-size: 1.5vw;
    text-decoration: underline;
}

#nonTechSkillsContainer {
    margin-top: 0;
    width: 90%;
    max-width: 825px;
}

#nonTechSkillsCategoryContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* border: 1px solid black; */
    /* height: 70vh; */
    justify-content: space-evenly;
}

@media screen and (max-width: 1300px) {
    #nonTechSkillsContainer {
        margin-top: 0;
        width: unset;
        max-width: unset;
    }
    #nonTechSkillsCategoryContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-evenly;
    }
    #nonTechSkillsContainer>h3 {
        margin-top: 2vh;
        font-size: 25px;
        text-decoration: underline;
    }
}