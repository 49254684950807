@import '../MultiViewStyles/Nav.css';
#topNavBarContainer {
    padding-top: 1vh;
    padding-bottom: 1vh;
    width: 100vw;
    right: 0;
}

#topNavBarItems {
    width: 40%;
    margin-right: 15px;
    margin-left: auto;
    list-style: none;
    display: flex;
    justify-content: space-evenly;
}

@media screen and (max-width: 1300px) {
    #topNavBarContainer {
        display: none;
    }
}