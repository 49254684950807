#detailedAboutMeContainer {
    width: 60vw;
    margin: auto;
}

#aboutMeCardsContainer {
    display: flex;
    flex: row;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 5%;
}

#descriptionCards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    flex-basis: 20%;
    margin: 0;
}

#faqHeading {
    font-size: 2vw;
}

#aboutMeSectionContainer {
    margin-bottom: 100px;
}

@media screen and (max-device-width: 1300px) {
    #detailedAboutMeContainer {
        width: 100vw;
    }
    #faqHeading {
        font-size: 30px;
    }
}