#skillsContainer, #techSkillsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* border: 1px solid black; */
    /* height: 70vh; */
    justify-content: center;
    height: fit-content;
    margin-bottom: 10vh;
}

#techSkillsContainer {
    padding-top: 0;
    margin: auto;
}

#homeSkillsSection {
    padding-top: 10vh;
}