nav {
    position: fixed;
    background-color: rgb(0, 0, 0, .2);
    z-index: 1;
}

.navItem>a, .navItem>button {
    text-decoration: none;
    font-size: x-large;
    text-shadow: 5px 5px 5px black;
    text-transform: uppercase;
    color: rgb(255, 255, 255, 1);
    font-weight: 300;
    filter: brightness(80%);
    animation: dimNavLinks .5s forwards;
}

.navItem>.active {
    text-decoration: underline;
    animation: brightenNavLinks 1s forwards;
    /* filter: brightness(150%); */
}

.navItem:hover a:not(.active), .navItem:hover button:not(.active) {
    cursor: pointer;
    animation: brightenNavLinks 1s forwards;
}

@keyframes brightenNavLinks {
    front {
        filter: brightness(80%);
    }
    to {
        filter: brightness(150%);
    }
}

@keyframes dimNavLinks {
    from {
        filter: brightness(150%);
    }
    to {
        filter: brightness(80%);
    }
}