.descriptionCard {
    width: 100%;
    height: 100%;
}

.descriptionCardWrapper {
    width: 8vw;
    height: 8vw;
    position: relative;
}

.descriptionCardText {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    z-index: 1;
}

.hexagonTriangleTop {
    float: left;
    border-right: 30px solid #6C6;
    border-top: 52px solid transparent;
    border-bottom: 52px solid transparent;
}

.hexagonContainer {
    width: 100%;
    height: 100%;
}

.hexagonRectangle {
    float: left;
    width: 60px;
    height: 104px;
}

.hexagonTriangleBottom {
    float: left;
    border-left: 30px solid #6C6;
    border-top: 52px solid transparent;
    border-bottom: 52px solid transparent;
}

.hexagon {
    /*background-color: #04c2c9;*/
    background-color: #29b0ac;
    /*background-color: #209794;*/
    display: inline-block;
    height: 100%;
    width: calc(100% * 0.57735);
}

.hexagon::before, .hexagon::after {
    background-color: inherit;
    content: "";
    height: inherit;
    position: absolute;
    right: calc((100% / 2) - ((100% * 0.57735) / 2));
    top: 0;
    width: inherit;
}

.hexagon::before {
    transform: rotateZ(60deg);
}

.hexagon::after {
    transform: rotateZ(-60deg);
}

.descriptionWord {
    /* height: 100%; */
    position: absolute;
    top: 30%;
    z-index: 1;
    font-size: 1.15vw;
    width: 100%;
    text-transform: uppercase;
    /* text-align: center; */
}

.descriptionWordText {
    margin-top: 15px;
    font-size: larger;
    margin-left: -15%;
    width: 130%;
}

[data-aos="rotate"] {
    transform: rotate(-1080deg);
    transition-property: transform;
}

[data-aos="rotate"].aos-animate {
    transform: rotate(0deg);
}

@media screen and (max-device-width: 1300px) {
    .descriptionCardWrapper {
        width: 25vw;
        height: 25vw;
        margin: 10px;
    }
    .descriptionWord {
        height: 100%;
        top: 0%;
        margin: 0%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 15px;
    }
}