.relevantCertificationsCardsContainer {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.relevantCertificationsHeading {
    color: black;
    font-size: x-large;
    text-decoration: underline;
}

.certificationItemWrapper {
    background-color: rgb(255, 255, 255, .8);
    width: 100%;
    margin-top: 20px;
}