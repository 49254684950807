.courseCard {
    width: 100%;
    height: 100%;
    background-color: #67973f;
    /* margin:  */
}

.courseCardWrapper {
    width: 24%;
    height: 12vh;
    margin: .5%;
}

.courseCard>.front {
    font-size: 1vw;
}

.courseCard>.back {
    /* font-size: .8vw;  */
    font-size: 1.5vh;
    background-color: #89ac60;
}

.courseName {
    font-size: 20px;
}

@media screen and (max-width: 1300px) {
    .courseCardWrapper {
        height: 50px;
        width: 30%;
    }
    .courseName {
        font-size: 12px;
    }
}