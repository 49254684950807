#contactSectionContainer {
    /* width: 80%; */
    margin: auto;
    height: 100vh;
    width: 100vw;
}

#contactCardContainer {
    display: flex;
    height: 65vh;
    flex-direction: column;
    justify-content: space-between;
}