@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
@import './styles/MultiViewStyles/Nav.css';
body {
    background-color: #282c34;
    overflow-y: scroll;
}

* {
    font-family: 'Source Sans Pro', sans-serif;
}

html {
    scroll-snap-type: y proximity;
    margin-bottom: 150px;
}

h1 {
    text-shadow: 5px 5px 5px black;
}

h1:not(#name) {
    width: 50%;
}

section>div>div:first-of-type {
    margin-top: 5vh;
}

#introBackgroundContainer {
    height: 100vh;
    width: 100vw;
    position: absolute;
    z-index: -1;
    /* background-image: url("./res/images/mountain3.jpg"); */
    /* background-image: url("./res/images/me_wedding_compressed_2.jpg"); */
    /* background-size: 115%; */
    /* background-position: 0 15%; */
    background-image: url("./res/images/me_wedding_compressed_cropped_4.jpg");
    background-size: 100%;
    background-position: 0 15%;
}

section {
    /* scroll-snap-align: start;
    scroll-snap-stop: normal; */
    /* height: 100vh; */
    padding: 1px;
}

.introSection {
    height: 100vh;
}

section>div {
    margin-top: 10vh !important;
}

#scrollToTopButtonWrapper {
    position: fixed;
    bottom: 3%;
    right: 5%;
    animation: displayScrollToTopButtonAnimation 1.5s forwards;
}

#scrollToTopButtonWrapper>button {
    all: unset;
}

.routerNavigationArrowContainer {
    top: 50%;
}

.navigationArrowContainer {
    position: absolute;
    z-index: 2;
    color: white;
    text-shadow: 2px 2px 2px black;
}

.navigationArrowContainer.fixed {
    position: fixed;
}

.fa-chevron-left, .fa-chevron-right, .fa-chevron-up, .fa-chevron-down {
    animation: arrowPulse 2s infinite;
    filter: drop-shadow(2px 2px 2px black);
    /* text-shadow: 2px 2px 2px black; */
}

.viewNavigationArrowContainer {
    left: 50vw;
}

#rightRouterNavigationArrowContainer {
    right: 50px;
}

#leftRouterNavigationArrowContainer {
    left: 50px;
}

.navigationArrowContainer:hover {
    cursor: pointer;
}

.routerNavigationArrowContainer>p {
    position: absolute;
    transform-origin: center;
    font-size: 20px;
    width: 120px;
    margin: 0;
    text-align: center;
    animation: viewNamePulse 2s infinite;
}

#rightRouterNavigationArrowContainer>p {
    transform: rotate(-90deg) translateY(-75px) translateY(-10px);
}

#leftRouterNavigationArrowContainer>p {
    transform: rotate(90deg) translateY(15px);
}

#scrollToTopButtonWrapper>button:hover {
    cursor: pointer;
}

.slide-right-enter {
    animation: animateRightEntry .75s forwards;
}

.slide-right-exit {
    animation: animateLeftExit .75s forwards;
}

.slide-left-enter {
    animation: animateLeftEntry .75s forwards;
}

.slide-left-exit {
    animation: animateRightExit .75s forwards;
}

#topLeftIcon {
    display: none;
}

[data-icon="arrow-circle-up"] {
    color: white;
}

@keyframes arrowPulse {
    0% {
        opacity: .2;
        transform: scale(1);
    }
    50% {
        opacity: 1;
        transform: scale(1.3);
    }
    100% {
        opacity: .2;
        transform: scale(1);
    }
}

@keyframes viewNamePulse {
    0% {
        opacity: .2;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: .2;
    }
}

@keyframes animateRightEntry {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0%);
    }
}

@keyframes animateLeftExit {
    from {
        transform: translateX(0%);
    }
    to {
        transform: translateX(-100%);
    }
}

@keyframes animateLeftEntry {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0%);
    }
}

@keyframes animateRightExit {
    from {
        transform: translateX(0%);
    }
    to {
        transform: translateX(100%);
    }
}

@keyframes displayScrollToTopButtonAnimation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media screen and (max-width: 1300px) {
    .routerNavigationArrowContainer {
        display: none;
    }
    #introBackgroundContainer {
        top: 5%;
        height: 30%;
        width: 60%;
        left: 20%;
        background-image: url("./res/images/me_wedding_mobile.jpg");
        background-repeat: no-repeat;
        background-size: 150%;
        background-position: 50% 0%;
        border-radius: 100%;
        z-index: 1;
    }
    #topLeftIcon {
        z-index: 5;
        display: block;
        position: fixed;
        top: 15px;
        left: 15px;
        height: fit-content;
        width: fit-content;
        /* filter: drop-shadow(6px 6px 6px solid black); */
    }
    h1 {
        width: 100% !important;
        font-size: 50px !important;
    }
    section>div:not(#introSection) {
        margin-top: unset !important;
    }
}