#projectsSectionContainer {
    margin: auto;
    margin-bottom: 100px;
}

#tagSearchBar {
    margin-top: 25px;
    margin-bottom: 25px;
    width: 30%;
    height: 1.5vw;
    font-size: 1.2vw;
}

#projectsSectionContainer>h2 {
    font-size: xx-large;
}

#allTagsContainer {
    margin: auto;
    width: 60%;
}

.tagsContainer {
    /* padding-top: 10px; */
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.tagTitle {
    margin-top: auto;
    margin-bottom: auto;
    font-weight: bold;
    font-size: 24px;
    display: inline;
}

.tag {
    padding: 10px;
    border: 1px solid white;
    margin: 5px;
    min-width: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* width: 0px; */
}

.tag.selectedTag {
    background-color: #67973f;
}

.tag:hover {
    cursor: pointer;
    background-color: #67973f;
}

.detailedProjectCardContainer.hide {
    animation: hideProject 1s forwards;
    /* display: none; */
    /* flex-grow: .000001; */
    /* transition: all 1s; */
}

.iframeContainer {
    width: 95vw;
    position: fixed;
    left: 50%;
    top: 35%;
    transform: translateX(-50%) translateY(-40%);
    height: 0;
    opacity: 0;
    background-color: white;
    z-index: 5
}

.iframeContainer>.x {
    position: absolute;
    top: -4%;
    left: 2%;
    color: blue;
    font-weight: 900;
    font-size: xx-large;
}

.iframeContainer>.x:hover {
    cursor: pointer;
}

.iframeContainer.selected {
    animation: showSample 1s forwards;
}

.iframeContainer.unselected {
    animation: hideSample 1s forwards;
}

.sample {
    height: 100%;
    width: 100%;
    z-index: 5;
    /* width: 100vw; */
}

.detailedProjectCardContainer.show {
    /* display: block; */
    /* flex-grow: 1; */
    animation: showProject 1s forwards;
}

#detailedProjectCardsContainer {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
}

#autocompleteContainer {
    position: relative;
    width: 200px;
    margin: auto;
}

#autocompleteContainer>input {
    width: 100%;
    margin: 25px auto 50px auto;
    height: 20px;
}

#autocompleteContainer>input:focus {
    outline: none;
}

#autocompleteContainer>::-webkit-scrollbar {
    width: 8px;
}

#autocompleteContainer>::-webkit-scrollbar-thumb {
    background-color: #979696;
    border-radius: 100px;
    width: 10px;
    background-clip: padding-box;
    border-left: 1px solid white;
    border-right: 1px solid white;
    z-index: -1;
}

#autocompleteResultsContainer {
    position: absolute;
    background-color: white;
    color: black;
    max-height: 150px;
    overflow-y: scroll;
    left: 50%;
    transform: translateX(calc(-50% + 4px)) translateY(-50px);
    width: calc(100% + 6px);
    padding: 0;
    margin-top: 1px;
    list-style-type: none;
    z-index: 100;
}

.autocompleteResult:hover {
    background-color: #E7E3E2;
    cursor: pointer;
}

.autocompleteResult {
    all: unset;
    display: block;
    padding-top: 3px;
    padding-bottom: 3px;
    width: 100%;
}

@keyframes showSample {
    0% {
        height: 0;
        opacity: 0;
    }
    100% {
        opacity: 1;
        height: 80vh;
    }
}

@keyframes hideSample {
    0% {
        /* display: block; */
        height: 80vh;
        opacity: 1;
    }
    100% {
        opacity: 0;
        visibility: hidden;
        height: 0;
        line-height: 0;
        overflow: hidden;
    }
}

@keyframes showProject {
    from {
        opacity: 0;
        /* width: 0; */
        /* flex-grow: .01; */
    }
    to {
        opacity: 1;
        /* width: initial; */
        /* flex-grow: 1; */
    }
}

@keyframes hideProject {
    from {
        /* flex-grow: 1; */
        /* width: initial; */
        opacity: 1;
    }
    to {
        opacity: 0;
        /* width: 0 */
        /* flex-grow: .1; */
    }
}

@media screen and (max-width: 1300px) {
    #allTagsContainer {
        width: 100%;
    }
    .tag {
        padding: 2px 5px;
        border: 1px solid white;
        margin: 5px;
        min-width: 10%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* width: 0px; */
    }
    .tagTitle {
        font-weight: bold;
        font-size: 20px;
        width: 100%;
        margin-top: 10px;
    }
    #detailedProjectCardsContainer {
        width: 100vw;
    }
}