#techSkillsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* border: 1px solid black; */
    /* height: 70vh; */
    height: fit-content;
}

#techSkillsContainer {
    padding-top: 0;
    margin: auto;
    margin-top: 0;
}

svg {
    margin-top: 0;
    padding-top: 0;
}

@media screen and (max-width: 1300px) {}