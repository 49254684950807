#projectsViewIntroSection {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    flex-direction: column;
    -ms-flex-direction: column;
    /* background-image: url('../../res/images/auroraBorealis.jpg');
    background-size: cover; */
    /* background-position: 10%; */
    /* background-position: center bottom; */
}

#projectsViewIntroSection>h1 {
    font-size: 4vw;
}

.projectViewArrow {
    cursor: pointer;
    border: solid white;
    display: inline-block;
    /* margin-left: 5vw; */
    padding: 7px;
    animation: arrowPulse 1.5s infinite;
}

.projectArrowContainer {
    position: absolute;
    left: 50%;
    bottom: 5vh;
    border-width: 4px 0 0 4px;
}