.detailedProjectCardContainer {
    width: 30vw;
    height: 40vh;
    margin: 2vh;
    border-radius: 10px;
    /* width: max-content;
    height: max-content; */
    background-color: #5c2756;
}

.detailedProjectCard {
    position: relative;
    color: white;
    width: 100%;
    height: 100%;
}

.detailedProjectCardInfo {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.detailedProjectCardProjectName {
    font-size: 2vw;
}

.detailedProjectCard>p {
    font-size: 1vw;
}

.detailedProjectCardClickablesContainer {
    position: absolute;
    bottom: 10px;
    width: 100%;
    display: flex;
}

.detailedProjectCardClickablesContainer button, .detailedProjectCardClickablesContainer a {
    all: initial;
    width: 20%;
    text-align: center;
    font-size: x-large;
    color: white;
    border: 2px solid white;
    padding: 1%;
    vertical-align: middle;
    font-family: 'Source Sans Pro', sans-serif;
}

.detailedProjectCardClickablesContainer button {
    margin-right: auto;
    margin-left: auto;
}

.detailedProjectCardClickablesContainer a {
    margin-right: auto;
    margin-left: auto;
}

.detailedProjectCardClickablesContainer button:hover, .detailedProjectCardClickablesContainer a:hover {
    cursor: pointer;
    background-color: #005e6c;
}

.status>.ongoing {
    color: #29b0ac;
}

.status>.inProgress {
    color: #faa41a;
}

.status>.completed {
    color: #67973f;
}

.detailedProjectCardProjectName {
    margin-bottom: 0;
}

@media screen and (max-width: 1300px) {
    .detailedProjectCardContainer {
        width: 100vw !important;
        height: 50vh;
        border-radius: 10px;
        background-color: #5c2756;
        margin: 25px 0;
    }
    .detailedProjectCard {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .detailedProjectCardProjectName {
        font-size: 20px;
    }
    .detailedProjectCard>p {
        font-size: 12px;
    }
    .detailedProjectCardClickablesContainer button, .detailedProjectCardClickablesContainer a {
        font-size: large;
        padding: 1%;
    }
}