.detailedExperienceCardContainer {
    background-color: #29b0ac;
    width: 60%;
    margin: auto;
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
    /* padding: 2%; */
    margin-bottom: 5%;
}

.detailedExperienceCardHeader, .detailedExperienceCardFooter {
    display: flex;
    padding-top: 2%;
    padding-bottom: 2%;
}

.detailedExperienceCardBody {
    padding-left: 2.5%;
    padding-right: 2.5%;
    border-bottom: .5px solid gray;
    /* justify-content: space-evenly; */
}

.detailedExperienceCardFooter {
    border-top: .5px solid gray;
    /* justify-content: space-evenly; */
}

.detailedExperienceCardHeader>.detailedExperienceCardCompanyLogo {
    margin-left: 5%;
    margin-right: auto;
}

.detailedExperienceCardHeader {
    border-bottom: .5px solid gray;
    text-transform: uppercase;
    /* justify-content: space-evenly; */
}

.detailedExperienceCardHeader>.detailedExperienceCardCompanyName {
    margin-left: 50%;
    transform: translateX(-50%);
    margin-right: auto;
    font-size: 1.25vw;
    text-align: center;
}

.detailedExperienceCardHeader>.detailedExperienceCardCompanyRole {
    margin-left: auto;
    margin-right: 5%;
    margin-top: auto;
    text-align: right;
}

.detailedExperienceCardFooter>.detailedExperienceCardCompanyWorkDuration {
    margin-left: 2.5%;
    /* margin-right: auto; */
}

.detailedExperienceCardFooter>.detailedExperienceCardCompanyWorkLocation {
    margin-left: auto;
    margin-right: 2.5%;
}

@media screen and (max-width: 1300px) {
    .experienceTypeHeading {
        text-align: center !important;
        margin-left: 0 !important;
    }
    .detailedExperienceCardContainer {
        width: 100%;
        margin: auto;
        text-align: left;
        padding-left: 0px;
        padding-right: 0px;
        /* padding: 2%; */
        margin-bottom: 5%;
    }
    .detailedExperienceCardHeader {
        border-bottom: .5px solid gray;
        text-transform: uppercase;
        /* justify-content: space-evenly; */
        text-align: center;
        flex-direction: column;
    }
    .detailedExperienceCardHeader>.detailedExperienceCardCompanyName {
        margin: unset;
        transform: translateX(0%);
        font-size: 20px;
        text-align: center;
    }
    .detailedExperienceCardHeader>.detailedExperienceCardCompanyRole {
        margin: unset;
        text-align: center;
    }
}