@import '../MultiViewStyles/Nav.css';
#homeViewNavBarContainer {
    width: 15vw;
    left: -15vw;
    display: flex;
    align-items: center;
    height: 100vh;
}

#homeViewNavBarItems {
    list-style: none;
    padding: 0;
    margin: auto;
    text-align: center;
}

#homeViewNavBarItems li+li {
    margin-top: 10vh;
}

#homeViewNavBarContainer.show {
    left: 0;
    transition: all .5s linear;
}

#homeViewNavBarContainer.hide {
    left: -15vw;
    transition: all .5s linear;
}

.homeViewNavBarLink {
    all: unset;
}

.homeViewNavBarLink:hover {
    cursor: pointer;
}

@media screen and (max-width: 1300px) {
    #homeViewNavBarContainer {
        /* display: none; */
        width: 50vw;
        left: -50vw;
        display: flex;
        align-items: center;
        height: 105vh;
        background-color: rgb(0, 0, 0, .8);
    }
    #homeViewNavBarContainer.show {
        left: 0;
        transition: all .5s linear;
    }
    #homeViewNavBarContainer.hide {
        left: -50vw;
        transition: all .5s linear;
    }
}