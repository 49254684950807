.educationCardWrapper {
    width: 100%;
    height: 50%;
}

.educationCardContainer {
    width: 100%;
    height: 90%;
}

.schoolHeader {
    display: flex;
    flex-direction: row;
}

.schoolName {
    display: inline-block;
    font-size: 2vw;
    /* text-decoration: underline; */
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
    color: black;
    text-transform: uppercase;
}

.educationContent {
    padding: 1%;
}

.educationDescription {
    color: black;
    margin-left: 0;
}

.enrolledDates {
    margin-right: 0;
    margin-left: auto;
    color: black;
}

.schoolLocation {
    margin-right: 0;
    margin-left: auto;
    text-decoration: none;
    font-size: 1.75vw;
    font-weight: bold;
    color: black;
}

.schoolHeaderSubText {
    font-size: 1.5vw;
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 1300px) {
    .schoolName {
        font-size: 20px;
    }
    .schoolLocation {
        font-size: 15px
    }
    .schoolHeaderSubText {
        font-size: 12px;
        display: flex;
        flex-direction: row;
    }
}