#projectsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10vh;
    height: 60vh;
    /* border: 1px solid black; */
    /* height: 70vh; */
}

#allProjectsLink {
    font-size: x-large;
    /* margin-bottom: 10vh; */
}

#allProjectsLinkContainer {
    border: 1px solid white;
    padding: 10px;
    display: inline;
    animation: revertAllProjectsLinkAnimation .5s forwards;
}

#allProjectsLinkContainer:hover {
    animation: invertAllProjectsLinkAnimation .5s forwards;
}

@keyframes invertAllProjectsLinkAnimation {
    from {
        filter: invert(0%);
        background-color: black;
    }
    to {
        filter: invert(100%);
        background-color: black;
    }
}

@keyframes revertAllProjectsLinkAnimation {
    from {
        background-color: black;
        filter: invert(100%);
    }
    to {
        background-color: transparent;
        filter: invert(0%);
    }
}

@media screen and (max-width: 1300px) {
    #projectsContainer {
        height: initial;
        margin-top: unset;
        margin-bottom: 20px;
    }
}