#contactView {
    text-align: center;
    color: white;
}

.contactViewArrow {
    cursor: pointer;
    border: solid white;
    display: inline-block;
    /* margin-left: 5vw; */
    padding: 7px;
    animation: arrowPulse 1.5s infinite;
}

.contactArrowContainer {
    position: absolute;
    left: 50%;
    bottom: 5vh;
    border-width: 4px 0 0 4px;
}

