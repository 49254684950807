.homePageSectionContainer {
    text-align: center;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* -------   Scroll snap causes issues in React Transition Group CSS Transitions    ------- */
    /* scroll-snap-align: start;
    scroll-snap-stop: normal; */
}

.homePageSectionContainer * {
    color: white;
    margin: auto;
    padding: 0;
}

.sectionContent:not(#introSection) {
    padding-left: 10vw;
    width: 60%;
    min-width: 850px;
}

.navItem>a {
    cursor: pointer;
}

h2 {
    top: 0;
    font-size: 40px;
    text-transform: uppercase;
}

.sectionContent {
    position: relative;
}

.sectionContent>div {
    display: flex;
    flex-direction: column;
    height: 70vh;
}

.navigationArrowContainer.up {
    top: 3vh;
}

.navigationArrowContainer.down {
    bottom: 3vh;
}

.homeNavigationArrowContainer.down {
    animation: arrowHomeIntroTransition 1s forwards;
}

.homeNavigationArrowContainer.up {
    animation: arrowDefaultPositionTransition 1s forwards;
}

.homeNavigationArrowContainer.down:not(.homeIntroPosition) {
    animation: arrowDefaultPositionTransition 1s forwards;
}

#upArrow {
    border-width: 0 4px 4px 0;
}

#downArrow {
    border-width: 4px 0 0 4px;
}

@keyframes arrowDefaultPositionTransition {
    0% {
        margin-left: 0vw;
    }
    100% {
        margin-left: 5vw;
    }
}

@keyframes arrowHomeIntroTransition {
    0% {
        margin-left: 5vw;
    }
    100% {
        margin-left: 0;
    }
}

@media screen and (max-width: 1300px) {
    .sectionContent {
        min-width: unset !important;
        width: unset !important;
        padding-left: unset !important;
    }
    .sectionContent>div {
        height: unset;
    }
    .homeNavigationArrowContainer {
        display: none;
    }
    .homePageSectionContainer {
        text-align: center;
        height: unset;
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .homePageSectionContainer h2 {
        margin-top: 50px;
        font-size: 35px;
    }
}