.experienceContainer {
    border: 1px solid #29b0ac;
    padding: 10px;
}

.experienceContent {
    background-color: rgb(255, 255, 255, .3);
    position: relative;
    padding: 1%;
}

.experienceSkillsTitle {
    text-decoration: underline;
    font-weight: bold;
}

.connectingLine {
    width: 11%;
    border-top: 2px dashed white;
    position: absolute;
    left: calc(-11% - 20px);
    top: 50%;
}

.experienceContent * {
    text-align: justify;
    padding: 2px 1px 2px 1px;
}

@media screen and (max-width: 1300px) {
    .connectingLine {
        display: none;
    }
    .experienceContainer {
        margin: 5px;
        /* border: unset; */
        /* padding: unset; */
    }
    .experienceContent * {
        padding: unset;
    }
    .experienceContent>h3 {
        font-size: 18px;
        height: 50px;
    }
    .atSymbol {
        display: none;
    }
    .companyName {
        display: block;
    }
}