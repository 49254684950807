#verticalBar {
    height: 100%;
    border: 2px solid #29b0ac;
    position: absolute;
    left: 0;
}

#experiencesContainer {
    position: relative;
    text-align: left;
    padding-left: 12%;
    margin-top: 0;
}

#allExperiencesLink {
    font-size: x-large;
    /* margin-bottom: 10vh; */
}

#allExperiencesLinkContainer {
    /* position: absolute; */
    border: 1px solid white;
    height: fit-content;
    padding: 10px;
    display: inline;
    animation: revertAllExperiencesLinkAnimation .5s forwards;
}

#allExperiencesLinkContainer:hover {
    animation: invertAllExperiencesLinkAnimation .5s forwards;
}

@keyframes invertAllExperiencesLinkAnimation {
    from {
        filter: invert(0%);
        background-color: transparent;
    }
    to {
        filter: invert(100%);
        background-color: black;
    }
}

@keyframes revertAllExperiencesLinkAnimation {
    from {
        background-color: black;
        filter: invert(100%);
    }
    to {
        background-color: transparent;
        filter: invert(0%);
    }
}

@media screen and (max-width: 1300px) {
    #verticalBar {
        display: none;
    }
    #experiencesContainer {
        padding-left: unset;
        margin-top: unset;
        margin-bottom: 20px;
    }
}